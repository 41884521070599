import React, { Component } from "react";
import logo from '../assets/dev_logo.png';

class Header extends Component {
  render() {
    return (
        <div id="header" className="container custom-section-container">
            <div className="row" style={{textAlign: "center"}}>
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <img
                        src={logo}
                        width='100px'
                        style={{margin: '10px'}}
                        alt='Logo'
                    />
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <h1
                        style={{
                            color: "rgba(0,0,0,.8)",
                            fontSize: "2em",
                            paddingTop: "15px",
                        }}
                    >
                        Delta Emerald is a leader in intelligent cannabis investing and industry insights.
                    </h1>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <h1
                        style={{
                            color: "rgba(0,0,0,.5)",
                            paddingTop: "15px",
                            fontSize: "2.5em",
                        }}
                    >
                        Our structural data advantage creates value for our partners.
                    </h1>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <a 
                        href="/thc-beverages"
                        style={{
                            color: "rgb(101, 149, 138)",
                            fontSize: "1.5em",
                            fontWeight: "bolder",
                            textDecoration: "none",
                            display: "inline-block",
                            marginTop: "20px"
                        }}
                    >
                        Check out our latest research on THC beverages.
                    </a>
                </div>
            </div>
        </div>
    )
  }
}

export default Header;