import React, { Component } from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';


class RootPage extends Component {
  render() {
    return (
      <div>
        <Header />

        {/* <AboutUs />

        <IndustryCrack />

        <DispatchClickbait />
        
        <Team />

        <Portfolio />

        <Testimonials /> */}

        <Footer />
      </div>
    )
  }
}


export default RootPage;
