import React, { Component } from "react";
import DigitalInfrastructure from '../../components/DigitalInfrastructure';
import Footer from '../../components/Footer';



class DigitalInfrastructurePage extends Component {
  render() {
    return (
        <div id="our-objective" className="container">
            <DigitalInfrastructure />
            <Footer />
        </div>
    )
  }
}


export default DigitalInfrastructurePage;
