import React, { Component } from 'react';


import Footer from '../../components/Footer';


class DiligenceBusiness extends Component {
  render() {
    return (
        <div>
          <div id='careers' className='container custom-opportunity-section-container'>
            <h6 className='custom-section-header'><a href="/diligence/welcome">Diligence</a></h6>
              <hr/>

              <h1
                style={{
                    fontSize: '4em',
                    color: 'rgba(0,0,0,.3)'
                }}
              >
                Business
              </h1>

              <hr/>
              <div className='row'>
                  <div className='col-md-12 mb-12'> 
                    <div>
                            <div>
                                <p
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '20px',
                                }}
                                >
                                In preparation for our conversation, please prepare documentation addressing the points/questions below.
                                </p>
                                
                                <br />

                                <div>
                                    <h5>Problem Space</h5>
                                    <ul>
                                        <li>How did you stumble upon the problem space that your venture is tackling?</li>
                                        <li>What are the top problems in this space?</li>
                                        <li>Why are these problems worth solving?</li>
                                        
                                    </ul>
                                </div>

                                <div>
                                    <h5>Customers</h5>
                                    <ul>
                                        <li>What process did you go through to narrow down to specific customer archetypes?</li>
                                        <li>What are your customer/user segments/archetypes?</li>
                                        <li>For each customer/user segment/archetype that you are serving, what problem statements are you addressing?</li>
                                        <li>How many paying customers do you have?</li>
                                        <li>Notable customers.</li>
                                        <li>Are you currently collecting any customer data? If so, what data?</li>
                                        <li>Customer churn statistics over time.</li>
                                    </ul>
                                </div>

                                <div>
                                    <h5>Solution Space</h5>
                                    <ul>
                                        <li>What is your product/service and current feature set?</li>
                                        <li>Tell us about version one of your product/service, and why/how it has evolved into the current feature set.</li>
                                        <li>What are the most used features? Why?</li>
                                        <li>What does customer onboarding look like? How long does it take? What are associated costs?</li>
                                        <li>What is currently on the product/service development roadmap?</li>
                                        <li>Product/service demonstration.</li>
                                        <li>User journeys per user archetype.</li>
                                        <li>Usage statistics over time.</li>
                                        <li>New problem statements as a function of existing feature set.</li>
                                    </ul>
                                </div>

                            </div>
                      </div>
                  </div>
              </div>
          </div>
          <Footer />
        </div>
    )
  }
}


export default DiligenceBusiness;
