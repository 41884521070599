import React, { Component } from 'react';
// import { Link } from 'react-router-dom';


class DispatchCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dispatch_data: props.dispatch_data
        }
    }
    render() {
    return (
        <div className="container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="project-card-custom container zoom">
                <a href={this.state.dispatch_data.link} target="_blank" rel="noreferrer">
                    <h3
                        style={{
                            color: 'rgba(0,0,0,.9)',
                            fontWeight: '800',
                            fontSize: '1.2em',
                        }}
                    >
                        {this.state.dispatch_data.title}
                    </h3>
                    <hr />
                    <h5
                        style={{
                            color: 'rgba(0,0,0,.7)',
                            // fontWeight: '800',
                            fontSize: '.9em',
                        }}
                    >
                        {this.state.dispatch_data.subtitle}
                    </h5>
                </a>
            </div>
        </div>
    )
  }
}


export default DispatchCard;