import React, { Component } from "react";


class DigitalInfrastructure extends Component {
    render() {
        return (
            <div id="header" className="container custom-section-container">
                <h1 className="custom-page-title">
                    DIGITAL INFRASTRUCTURE
                </h1>
                <br />
                <div className="row">
                    <div className="col-md-5 col-sm-5 col-xs-12">
                        <h1
                            style={{
                                color: "rgba(0,0,0,.8)",
                                fontSize: "2.4em",
                                marginTop: "40px",
                                
                            }}
                            >
                            Success in today's world requires the <span style={{ fontWeight: 'bold' }}>effective use of data</span>.
                        </h1>
                    </div>
                    <div className="col-md-7 col-sm-7 col-xs-12">
                        <h1
                            style={{
                                borderLeft: "solid",
                                paddingLeft: "70px",
                                color: "rgba(0,0,0,.5)",
                                fontSize: "1.7em",
                                marginTop: "50px",
                            }}
                        >
                            We help leading cannabis companies build <span style={{ fontWeight: 'bold' }}>informational edges</span>.
                        </h1>
                    </div>
                    
                    <div className="col-md-5 col-sm-5 col-xs-12">
                        <h1
                            style={{
                                color: "rgba(0,0,0,.8)",
                                fontSize: "2.4em",
                                marginTop: "40px",
                                
                            }}
                            >
                            We advocate <span style={{ fontWeight: 'bold' }}>hands-on learning</span> and <span style={{ fontWeight: 'bold' }}>leading by example</span>.
                        </h1>
                    </div>
                    <div className="col-md-7 col-sm-7 col-xs-12">
                        <h1
                            style={{
                                borderLeft: "solid",
                                paddingLeft: "70px",
                                color: "rgba(0,0,0,.5)",
                                fontSize: "1.7em",
                                marginTop: "50px",
                            }}
                        >
                            We've built <a href="/what-we-do/proprietary-data" style={{color:"#65958a"}}>digital infrastructure</a> and <a href="https://www.cannadatacon.com/" style={{color:"#65958a"}}>talent hubs</a> ourselves, <span style={{ fontWeight: 'bold' }}>fostering empathy and trust</span> with our founders.
                        </h1>
                    </div>
                </div>
            </div>
        )
    }
}


export default DigitalInfrastructure;
