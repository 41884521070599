import React from 'react';
import unscriptedLogo from '../assets/source_logos/dev_logo.png';
import cannadataconLogo from '../assets/source_logos/cannadatacon_logo__simple.png';
import dispatchLogo from '../assets/source_logos/dev_logo.png';

// const data = [
//   {
//     "title": "THC vs. Tequila, Guess Who's Winning in Texas?",
//     "date": "November 2024",
//     "category": "unscripted",
//     "type": "Delta Emerald Unscripted Video & Audio",
//     "substack_link": "",
//     "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_thc-vs-tequila-guess-whos-winning-in-texas-activity-7260267366251401217-TDF8?utm_source=share&utm_medium=member_desktop",
//     "youtube_link": "https://youtu.be/9Xc5VgBnF1I",
//     "spotify_link": "https://open.spotify.com/episode/273180tzH3uu8tdWftE1oO",
//     "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-thc-vs-tequila-guess-whos/id1769090220?i=1000676060448"
//   },
//   {
//     "title": "Lessons in Liquid Innovation",
//     "date": "October 2024",
//     "category": "unscripted",
//     "type": "Delta Emerald Unscripted Video & Audio",
//     "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_what-can-middle-school-teachers-teach-us-activity-7255182194699124737-gvJG?utm_source=share&utm_medium=member_desktop",
//     "youtube_link": "https://www.youtube.com/watch?v=nd__0FkHuBU",
//     "spotify_link": "https://open.spotify.com/episode/0hAIDH8D0Ogz3AsUPKUAPx",
//     "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-lessons-in-liquid-innovation/id1769090220?i=1000674220604"
//   },
//   {
//     "title": "Can THC Crack 20% of Liquor Store Sales?",
//     "date": "October 2024",
//     "category": "unscripted",
//     "type": "Delta Emerald Unscripted Video & Audio",
//     "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_how-long-til-thc-cracks-20-of-liquor-store-activity-7252634569693097984-IguA?utm_source=share&utm_medium=member_desktop",
//     "youtube_link": "https://youtu.be/B2l0XfBTdsA",
//     "spotify_link": "https://open.spotify.com/episode/2Qmcl6l5VmaLJtOtbFN4Ih",
//     "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-can-thc-crack-20-of-liquor/id1769090220?i=1000673424887"
//   },
//   {
//     "title": "How Cannabis is like FarmVille and Five Key Ingredients for THC Drinks",
//     "date": "October 2024",
//     "category": "unscripted",
//     "type": "Delta Emerald Unscripted Video & Audio",
//     "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_from-pixels-to-potables-how-breaking-stigmas-activity-7250105302782554112-r_zW?utm_source=share&utm_medium=member_desktop",
//     "youtube_link": "https://www.youtube.com/watch?v=ZVDDdi_QtUM",
//     "spotify_link": "https://open.spotify.com/episode/1izgMbUXI5C5AmUQwqSuJk",
//     "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-the-parallels-between/id1769090220?i=1000672498746"
//   },
//   {
//     "title": "How a Leading THC Drink Brand Converts In-Store Customers",
//     "date": "October 2024",
//     "category": "unscripted",
//     "type": "Delta Emerald Unscripted Video & Audio",
//     "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_thc-drinks-born-on-the-digital-shelf-can-activity-7247568632329699328-aMm7?utm_source=share&utm_medium=member_desktop",
//     "youtube_link": "https://www.youtube.com/watch?v=nZl6GTpYSJg&t=4s",
//     "spotify_link": "https://open.spotify.com/episode/4mpNNWM9cnEpj1INlpuApz",
//     "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-this-one-trick-helped-a/id1769090220?i=1000671660390"
//   },
//   {
//     "title": "Behind the Scenes of THC Beverage Policy",
//     "date": "September 2024",
//     "category": "unscripted",
//     "type": "Delta Emerald Unscripted Video & Audio",
//     "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_with-nbwa-2024-just-days-away-were-dropping-activity-7244307099248140289-dFVY?utm_source=share&utm_medium=member_desktop",
//     "youtube_link": "https://youtu.be/MD1pCEi64-M?si=RCpl8QFiDmmx17pj",
//     "spotify_link": "https://open.spotify.com/episode/7ko30unuOI6FipgldItf7X",
//     "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-behind-the-scenes-of/id1769090220?i=1000670521835"
//   },
//   {
//     "title": "A Beer Industry Veteran's View on THC Drinks",
//     "date": "September 2024",
//     "category": "unscripted",
//     "type": "Delta Emerald Unscripted Video & Audio",
//     "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_eric-best-former-chairman-of-the-national-activity-7237089588207394816-51an?utm_source=share&utm_medium=member_desktop",
//     "youtube_link": "https://youtu.be/QCgwhaSNNc8?si=_3ZRbGcXiD-eC5qN",
//     "spotify_link": "https://open.spotify.com/episode/5PuT1urXE8rVrQnImbHysz",
//     "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-a-beverage-insiders/id1769090220?i=1000669866567"
//   },
//   {
//     "title": "THC Drinks and the Digital Shelf",
//     "date": "August 2024",
//     "category": "unscripted",
//     "type": "Delta Emerald Unscripted Video & Audio",
//     "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_aaron-nosbisch-founder-of-br%C4%93z-and-lucyd-activity-7232376397854363648-IZuL?utm_source=share&utm_medium=member_desktop",
//     "youtube_link": "https://youtu.be/Hw80MeYJzNI?si=cH7QZ_0m6JfFuXli",
//     "spotify_link": "https://open.spotify.com/episode/532aVyrrbtf0VA0F8gIPtY",
//     "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-thc-drinks-and-the-digital-shelf/id1769090220?i=1000669866588"
//   },
//   {
//     "title": "THC Drinks and Cannabis Regulations",
//     "date": "April 2024",
//     "category": "cannadatacon",
//     "type": "CannaDataCon2024 Video",
//     "youtube_link": "https://www.youtube.com/watch?v=CLp0yFPAc84"
//   },
//   {
//     "title": "Delta Emerald's Views on THC Drinks",
//     "date": "April 2024",
//     "category": "cannadatacon",
//     "type": "CannaDataCon2024 Video",
//     "youtube_link": "https://www.youtube.com/watch?v=zvVjbTvNmsU&t=16s"
//   },
//   {
//     "title": "Trump and Cannabis Reform",
//     "date": "November 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/trump-and-cannabis-reform",
//     "linkedin_link": "",
//     "youtube_link": "",
//     "spotify_link": "",
//     "apple_podcasts_link": ""
//   },
//   {
//     "title": "The Win-Win for THC Drinks, Part III",
//     "date": "November 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/the-win-win-for-thc-drinks-part-iii"
//   },
//   {
//     "title": "Embracing THC Drink Regulations, Part II",
//     "date": "October 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/embracing-thc-drink-regulations-part"
//   },
//   {
//     "title": "The Great Convergence of Beer and THC Drinks",
//     "date": "October 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/the-great-convergence-of-beer-and"
//   },
//   {
//     "title": "The Win-Win for THC Drinks, Part II",
//     "date": "September 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/the-win-win-for-thc-drinks-part-ii"
//   },
//   {
//     "title": "The Win-Win for THC Drinks, Part I",
//     "date": "September 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/the-win-win-for-thc-drinks-part-i"
//   },
//   {
//     "title": "Alcohol Regulators Can't Keep Cannabis Out of Their Mouths",
//     "date": "August 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/why-alcohol-regulators-cant-keep"
//   },
//   {
//     "title": "Tilray's Trojan THC Horse",
//     "date": "August 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/tilrays-trojan-thc-horse"
//   },
//   {
//     "title": "What Cannabinoid Regulators Think",
//     "date": "July 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/what-cannabinoid-regulators-think"
//   },
//   {
//     "title": "Dispatch from Mecca (of THC Drinks)",
//     "date": "June 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/dispatch-from-mecca-of-thc-drinks"
//   },
//   {
//     "title": "Bigger Than Alcohol, Part III",
//     "date": "March 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/bigger-than-alcohol-part-iii"
//   },
//   {
//     "title": "The Florida Hemp Opportunity",
//     "date": "February 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/the-florida-hemp-opportunity"
//   },
//   {
//     "title": "Bigger Than Alcohol, Part II",
//     "date": "January 2024",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/bigger-than-alcohol-part-ii"
//   },
//   {
//     "title": "Bigger Than Alcohol, Part I",
//     "date": "November 2023",
//     "category": "dispatch",
//     "type": "The Delta Dispatch Essay",
//     "substack_link": "https://deltaemerald.substack.com/p/bigger-than-alcohol"
//   }
// ];

const data = [
  {
    "title": "How Big Beer Controls Your Drink Options",
    "date": "November 2024",
    "category": "unscripted",
    "type": "Delta Emerald Unscripted Video & Audio",
    "substack_link": "",
    "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_how-big-beer-controls-your-drink-options-activity-7262820700753735681-7GXm?utm_source=share&utm_medium=member_desktop",
    "youtube_link": "https://youtu.be/32QWAQhssHE?si=08e8S1KzTWoLTHkY",
    "spotify_link": "https://open.spotify.com/episode/3Iul0vjqx02QExZtxdfpMH",
    "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/how-big-beer-controls-your-drink-options-009/id1769090220?i=1000676950588"
  },
  {
    "title": "THC vs. Tequila, Guess Who's Winning in Texas?",
    "date": "November 2024",
    "category": "unscripted",
    "type": "Delta Emerald Unscripted Video & Audio",
    "substack_link": "",
    "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_thc-vs-tequila-guess-whos-winning-in-texas-activity-7260267366251401217-TDF8?utm_source=share&utm_medium=member_desktop",
    "youtube_link": "https://youtu.be/9Xc5VgBnF1I",
    "spotify_link": "https://open.spotify.com/episode/273180tzH3uu8tdWftE1oO",
    "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-thc-vs-tequila-guess-whos/id1769090220?i=1000676060448"
  },
  {
    "title": " Lessons in Liquid Innovation",
    "date": "October 2024",
    "category": "unscripted",
    "type": "Delta Emerald Unscripted Video & Audio",
    "substack_link": "",
    "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_what-can-middle-school-teachers-teach-us-activity-7255182194699124737-gvJG?utm_source=share&utm_medium=member_desktop",
    "youtube_link": "https://www.youtube.com/watch?v=nd__0FkHuBU",
    "spotify_link": "https://open.spotify.com/episode/0hAIDH8D0Ogz3AsUPKUAPx",
    "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-lessons-in-liquid-innovation/id1769090220?i=1000674220604"
  },
  {
    "title": "Can THC Crack 20% of Liquor Store Sales?",
    "date": "October 2024",
    "category": "unscripted",
    "type": "Delta Emerald Unscripted Video & Audio",
    "substack_link": "",
    "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_how-long-til-thc-cracks-20-of-liquor-store-activity-7252634569693097984-IguA?utm_source=share&utm_medium=member_desktop",
    "youtube_link": "https://youtu.be/B2l0XfBTdsA",
    "spotify_link": "https://open.spotify.com/episode/2Qmcl6l5VmaLJtOtbFN4Ih",
    "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-can-thc-crack-20-of-liquor/id1769090220?i=1000673424887"
  },
  {
    "title": "How Cannabis is like FarmVille and Five Key Ingredients for THC Drinks",
    "date": "October 2024",
    "category": "unscripted",
    "type": "Delta Emerald Unscripted Video & Audio",
    "substack_link": "",
    "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_from-pixels-to-potables-how-breaking-stigmas-activity-7250105302782554112-r_zW?utm_source=share&utm_medium=member_desktop",
    "youtube_link": "https://www.youtube.com/watch?v=ZVDDdi_QtUM",
    "spotify_link": "https://open.spotify.com/episode/1izgMbUXI5C5AmUQwqSuJk",
    "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-the-parallels-between/id1769090220?i=1000672498746"
  },
  {
    "title": "How a Leading THC Drink Brand Converts In-Store Customers",
    "date": "October 2024",
    "category": "unscripted",
    "type": "Delta Emerald Unscripted Video & Audio",
    "substack_link": "",
    "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_thc-drinks-born-on-the-digital-shelf-can-activity-7247568632329699328-aMm7?utm_source=share&utm_medium=member_desktop",
    "youtube_link": "https://www.youtube.com/watch?v=nZl6GTpYSJg&t=4s",
    "spotify_link": "https://open.spotify.com/episode/4mpNNWM9cnEpj1INlpuApz",
    "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-this-one-trick-helped-a/id1769090220?i=1000671660390"
  },
  {
    "title": "Behind the Scenes of THC Beverage Policy",
    "date": "September 2024",
    "category": "unscripted",
    "type": "Delta Emerald Unscripted Video & Audio",
    "substack_link": "",
    "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_with-nbwa-2024-just-days-away-were-dropping-activity-7244307099248140289-dFVY?utm_source=share&utm_medium=member_desktop",
    "youtube_link": "https://youtu.be/MD1pCEi64-M?si=RCpl8QFiDmmx17pj",
    "spotify_link": "https://open.spotify.com/episode/7ko30unuOI6FipgldItf7X",
    "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-behind-the-scenes-of/id1769090220?i=1000670521835"
  },
  {
    "title": "A Beer Industry Veteran’s View on THC Drinks",
    "date": "September 2024",
    "category": "unscripted",
    "type": "Delta Emerald Unscripted Video & Audio",
    "substack_link": "",
    "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_eric-best-former-chairman-of-the-national-activity-7237089588207394816-51an?utm_source=share&utm_medium=member_desktop",
    "youtube_link": "https://youtu.be/QCgwhaSNNc8?si=_3ZRbGcXiD-eC5qN",
    "spotify_link": "https://open.spotify.com/episode/5PuT1urXE8rVrQnImbHysz",
    "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-a-beverage-insiders/id1769090220?i=1000669866567"
  },
  {
    "title": "THC Drinks and the Digital Shelf",
    "date": "August 2024",
    "category": "unscripted",
    "type": "Delta Emerald Unscripted Video & Audio",
    "substack_link": "",
    "linkedin_link": "https://www.linkedin.com/posts/iancdominguez_aaron-nosbisch-founder-of-br%C4%93z-and-lucyd-activity-7232376397854363648-IZuL?utm_source=share&utm_medium=member_desktop",
    "youtube_link": "https://youtu.be/Hw80MeYJzNI?si=cH7QZ_0m6JfFuXli",
    "spotify_link": "https://open.spotify.com/episode/532aVyrrbtf0VA0F8gIPtY",
    "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-thc-drinks-and-the-digital-shelf/id1769090220?i=1000669866588"
  },
  {
    "title": "THC Drinks and Cannabis Regulations",
    "date": "April 2024",
    "category": "cannadatacon",
    "type": "CannaDataCon2024 Video",
    "substack_link": "",
    "linkedin_link": "",
    "youtube_link": "https://www.youtube.com/watch?v=CLp0yFPAc84",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "Delta Emerald’s Views on THC Drinks",
    "date": "April 2024",
    "category": "cannadatacon",
    "type": "CannaDataCon2024 Video",
    "substack_link": "",
    "linkedin_link": "",
    "youtube_link": "https://www.youtube.com/watch?v=zvVjbTvNmsU&t=16s",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "Trump and Cannabis Reform",
    "date": "November 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/trump-and-cannabis-reform",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "The Win-Win for THC Drinks, Part III",
    "date": "November 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/the-win-win-for-thc-drinks-part-iii",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "Embracing THC Drink Regulations, Part II",
    "date": "October 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/embracing-thc-drink-regulations-part",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "The Great Convergence of Beer and THC Drinks",
    "date": "October 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/the-great-convergence-of-beer-and",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "The Win-Win for THC Drinks, Part II",
    "date": "September 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/the-win-win-for-thc-drinks-part-ii",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "The Win-Win for THC Drinks, Part I",
    "date": "September 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/the-win-win-for-thc-drinks-part-i",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "Alcohol Regulators Can’t Keep Cannabis Out of Their Mouths",
    "date": "August 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/why-alcohol-regulators-cant-keep",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "Tilray’s Trojan THC Horse",
    "date": "August 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/tilrays-trojan-thc-horse",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "What Cannabinoid Regulators Think",
    "date": "July 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/what-cannabinoid-regulators-think",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "Dispatch from Mecca (of THC Drinks)",
    "date": "June 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/dispatch-from-mecca-of-thc-drinks",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "Bigger Than Alcohol, Part III",
    "date": "March 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/bigger-than-alcohol-part-iii",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "The Florida Hemp Opportunity",
    "date": "February 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/the-florida-hemp-opportunity",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "Bigger Than Alcohol, Part II",
    "date": "January 2024",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/bigger-than-alcohol-part-ii",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  },
  {
    "title": "Bigger Than Alcohol, Part I",
    "date": "November 2023",
    "category": "dispatch",
    "type": "The Delta Dispatch Essay",
    "substack_link": "https://deltaemerald.substack.com/p/bigger-than-alcohol",
    "linkedin_link": "",
    "youtube_link": "",
    "spotify_link": "",
    "apple_podcasts_link": ""
  }
];

const DeltaEmeraldContent = () => {
  const getLogo = (platform) => {
    switch (platform) {
      case 'youtube':
        return 'https://img.shields.io/badge/YouTube-FF0000?style=for-the-badge&logo=youtube&logoColor=white';
      case 'spotify':
        return 'https://img.shields.io/badge/Spotify-1ED760?style=for-the-badge&logo=spotify&logoColor=white';
      case 'apple':
        return 'https://img.shields.io/badge/Apple_Podcasts-9933CC?style=for-the-badge&logo=apple-podcasts&logoColor=white';
      case 'linkedin':
        return 'https://img.shields.io/badge/LinkedIn-0077B5?style=for-the-badge&logo=linkedin&logoColor=white';
      default:
        return null;
    }
  };

  const getCategoryLogo = (category) => {
    switch (category) {
      case 'Delta Emerald Unscripted':
      case 'unscripted':
        return unscriptedLogo;
      case 'CannaDataCon':
      case 'cannadatacon':
        return cannadataconLogo;
      case 'Delta Dispatch':
      case 'dispatch':
        return dispatchLogo;
      default:
        return null;
    }
  };

  const renderLinks = (item) => {
    const links = [];
    if (item.substack_link) links.push(
      <div key="substack">
        <a 
          href={item.substack_link} 
          target="_blank" 
          rel="noopener noreferrer"
          style={{ color: 'rgb(101, 149, 138)', textDecoration: 'underline' }}
        >
          Substack
        </a>
      </div>
    );
    if (item.linkedin_link) links.push(
      <div key="linkedin">
        <a href={item.linkedin_link} target="_blank" rel="noopener noreferrer">
          <img src={getLogo('linkedin')} alt="LinkedIn" style={{ height: '24px' }} />
        </a>
      </div>
    );
    if (item.youtube_link) links.push(
      <div key="youtube">
        <a href={item.youtube_link} target="_blank" rel="noopener noreferrer">
          <img src={getLogo('youtube')} alt="YouTube" style={{ height: '24px' }} />
        </a>
      </div>
    );
    if (item.spotify_link) links.push(
      <div key="spotify">
        <a href={item.spotify_link} target="_blank" rel="noopener noreferrer">
          <img src={getLogo('spotify')} alt="Spotify" style={{ height: '24px' }} />
        </a>
      </div>
    );
    if (item.apple_podcasts_link) links.push(
      <div key="apple">
        <a href={item.apple_podcasts_link} target="_blank" rel="noopener noreferrer">
          <img src={getLogo('apple')} alt="Apple Podcasts" style={{ height: '24px' }} />
        </a>
      </div>
    );
    return links;
  };

  return (
    <div id="DeltaEmeraldContent" className="container custom-section-container">
      <h1 className="custom-page-title">PUBLIC THC BEVERAGE RESEARCH</h1>
      <br />
      <div className="row">
        <table className="table table-striped larger-text">
          <thead>
            <tr>
              <th>Title</th>
              <th></th>
              <th>Links</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className="align-middle">
                  {getCategoryLogo(item.category.toLowerCase()) && (
                    <img 
                      src={getCategoryLogo(item.category.toLowerCase())} 
                      alt={`${item.category} logo`} 
                      style={{ height: '', width: '35px', margin: '5px', verticalAlign: 'middle' }} 
                    />
                  )}
                </td>
                <td className="align-middle">{item.title}</td>
                <td className="align-middle">
                  <div className="d-flex align-items-center justify-content-start">
                    {renderLinks(item)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <style jsx>{`
        .larger-text {
          font-size: 1.1rem;
        }
        .larger-text th {
          font-size: 1.2rem;
        }
        .table td {
          vertical-align: middle;
        }
        .table td > div {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      `}</style>
    </div>
  );
};

export default DeltaEmeraldContent;