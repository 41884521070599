import React, { Component } from "react";
import ProprietaryData from '../../components/ProprietaryData';
import IndustryCrack from '../../components/IndustryCrack';

import Footer from '../../components/Footer';


class ProprietaryData__PAGE extends Component {
  render() {
    return (
        <div id="private-equity" className="container">
            <ProprietaryData />
            {/* <br/> */}
            <hr/>
            <br/>
            <br/>
            <br/>
            <IndustryCrack />
            <Footer />
        </div>
    )
  }
}


export default ProprietaryData__PAGE;