import React, { Component } from "react";
// import logo from "../assets/dev_logo.png";


class Values extends Component {
  render() {
    return (
        <div id="header" className="container custom-section-container">
        <h1 className="custom-page-title">
            Delta Emerald carries layers of meaning:
        </h1>
        <br />
        <div className="row">
            <div className="col-md-5 col-sm-5 col-xs-12">
                <h1
                    style={{
                        color: "rgba(0,0,0,.8)",
                        fontSize: "2.4em",
                        marginTop: "50px",
                        
                    }}
                    >
                    In mathematics, <span style={{ fontWeight: 'bold' }}>delta means change</span>.
                </h1>
            </div>
            <div className="col-md-7 col-sm-7 col-xs-12">
                <h1
                    style={{
                        borderLeft: "solid",
                        paddingLeft: "70px",
                        color: "rgba(0,0,0,.5)",
                        fontSize: "1.7em",
                        marginTop: "50px",
                    }}
                >
                    We believe <span style={{ fontWeight: 'bold' }}>great opportunities come from harnessing change in consumer behavior</span>.
                    
                </h1>
            </div>
            
            <div className="col-md-5 col-sm-5 col-xs-12">
                <h1
                    style={{
                        color: "rgba(0,0,0,.8)",
                        fontSize: "2.4em",
                        marginTop: "50px",
                        
                    }}
                    >
                    In ecology, <span style={{ fontWeight: 'bold' }}>deltas send nutrients to an ecosystem</span>.
                </h1>
            </div>
            <div className="col-md-7 col-sm-7 col-xs-12">
                <h1
                    style={{
                        borderLeft: "solid",
                        paddingLeft: "70px",
                        color: "rgba(0,0,0,.5)",
                        fontSize: "1.7em",
                        marginTop: "50px",
                    }}
                >
                    <a href="/firm/objective" style={{color:"#65958a"}}>Our objective</a> is to serve as a <span style={{ fontWeight: 'bold' }}>hub for information</span>, <span style={{ fontWeight: 'bold' }}>talent</span>, and <span style={{ fontWeight: 'bold' }}>institutional investment</span> as the industry grows.
                </h1>
            </div>
            <div className="col-md-5 col-sm-5 col-xs-12">
                <h1
                    style={{
                        color: "rgba(0,0,0,.8)",
                        fontSize: "2.4em",
                        marginTop: "50px",
                        
                    }}
                    >
                    In our founder’s journey, <span style={{ fontWeight: 'bold' }}>‘Delta’ means relentlessly pursuing one's calling</span>.
                </h1>
            </div>
            <div className="col-md-7 col-sm-7 col-xs-12">
                <h1
                    style={{
                        borderLeft: "solid",
                        paddingLeft: "70px",
                        color: "rgba(0,0,0,.5)",
                        fontSize: "1.7em",
                        marginTop: "50px",
                    }}
                >
                    <a href="/firm/people" style={{color:"#65958a"}}>Ian</a>’s father, Miguel Angel Dominguez (1952-2007), epitomized the American Dream. Originating from Mexico City, Miguel became a naturalized U.S. citizen and founded Delta Designers, a construction firm in Houston. Ian spent his teen summers working at Delta Designers. <span style={{ fontWeight: 'bold' }}>Delta Emerald honors past generations and those aiming to improve the world</span>.
                </h1>
            </div>
            <h1
                    style={{
                        // borderLeft: "solid",
                        // paddingLeft: "70px",
                        color: "rgba(0,0,0,.5)",
                        fontSize: "1.7em",
                        marginTop: "70px",
                        // textAlign: "center",
                    }}
                >
                    The Delta Emerald logo is inspired by <a href="https://en.wikipedia.org/wiki/Patterns_in_nature" target="_blank" rel="noreferrer" style={{color:"#65958a"}}>sacred geometry</a> found in nature.
                </h1>
        </div>
    </div>
    )
  }
}


export default Values;
