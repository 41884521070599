import React, { Component } from "react";
import PrivateEquity from '../../components/PrivateEquity';
import Footer from '../../components/Footer';


class PrivateEquity__PAGE extends Component {
  render() {
    return (
        <div id="private-equity" className="container">
            <PrivateEquity />
            <Footer />
        </div>
    )
  }
}


export default PrivateEquity__PAGE;