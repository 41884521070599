import React, { Component } from 'react';


import Footer from '../../components/Footer';


class DiligenceFinance extends Component {
  render() {
    return (
        <div>
          <div id='careers' className='container custom-opportunity-section-container'>
            <h6 className='custom-section-header'><a href="/diligence/welcome">Diligence</a></h6>
              <hr/>

              <h1
                style={{
                    fontSize: '4em',
                    color: 'rgba(0,0,0,.3)'
                }}
              >
                Finance
              </h1>

              <hr/>
              <div className='row'>
                  <div className='col-md-12 mb-12'> 
                    <div>
                            <div>
                                <p
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '20px',
                                }}
                                >
                                In preparation for our conversation, please prepare documentation addressing the points/questions below.
                                </p>
                                
                                <br />

                                <div>
                                    <ul>
                                        <li>Historical monthly financials (P&amp;L, balance sheet and statement of cash flows).</li>
                                        <li>This year’s financial forecast and budget, including variance analysis of budget vs. actuals by month.</li>
                                        <li>Detailed financial model.</li>
                                        <li>Monthly time-series of revenues, contribution by customer.</li>
                                        <li>Detailed breakdown of unit economics for each product and / or service.</li>
                                        <li>Customer top-of-funnel => conversion statistics by month.</li>
                                        <li>Raise details (valuation, amount raised, etcetera).</li>
                                    </ul>
                                </div>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
          <Footer />
        </div>
    )
  }
}


export default DiligenceFinance;
