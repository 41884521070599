import React, { Component } from "react";
import DispatchClickbait from '../../components/DispatchClickbait';
import Footer from '../../components/Footer';


class ViewAll__PAGE extends Component {
  render() {
    return (
        <div id="our-DispatchClickbait" className="container">
            <DispatchClickbait />
            <Footer />
        </div>
    )
  }
}


export default ViewAll__PAGE;