import React, { Component } from "react";
import Footer from '../../components/Footer';
import Privacy from '../../components/Privacy';

class Privacy__PAGE extends Component {
  render() {
    return (
        <div id="Privacy__PAGE" className="container">
           <Privacy />
           <Footer />
        </div>
    )
  }
}


export default Privacy__PAGE;