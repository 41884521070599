
import React, { Component } from 'react';
import logo_nb from '../assets/dev_logo_nb.png';


class Footer extends Component {
  render() {

    return (
        <footer
            className=''
            style={{
                textAlign: 'center',
                // color: '#1c7b65',
                // color: 'white',
                // backgroundColor: 'black',
                marginTop: '5%',
                padding: '100px 0px'
            }}
        >
            <div className='container' style={{color: "rgba(0,0,0,.8)", fontSize:".9em"}}>
                Delta Emerald Ventures Advisors LLC
                <br />
                408 Broadway, 2nd Floor
                <br />
                New York, NY 10013
            </div>
            <img src={logo_nb} width='60px' style={{margin: '20px'}} alt='Logo' />
            <div
                className='container'
                style={{
                    fontSize: '.8em'
                }}
            >
                <a href="/legal" target="_blank"> Legal </a> | 
                <a href="/Privacy" target="_blank"> Privacy </a>
            </div>
            <div className='container' style={{color: "rgba(0,0,0,.4)", fontSize:".9em"}}>
                Copyright &copy; Delta Emerald Ventures Advisors LLC {(new Date().getFullYear())}.
            </div>
            <div className='container' style={{color: "rgba(0,0,0,.4)", fontSize:".9em"}}>
                All rights reserved.
            </div>
            <div className='container' style={{color: "rgba(0,0,0,.01)", fontSize:".2em"}}>
                At Delta Emerald Ventures, we lead in cannabis technology investing and industry insights, leveraging our unique data advantage to benefit investors and founders. Our stage agnostic private equity investments span the cannabis industry, focusing on mission-critical software and comprehensive value chain involvement. Our vision is to be a central hub for information, talent, and intelligent capital in the evolving cannabis sector. Data is foundational to our approach, with a proprietary platform informing our investment strategies and enhancing the operational performance of our portfolio companies. Delta Emerald is committed to progress and innovation in the cannabis industry, honoring our legacy while driving transformative change.
            </div>
        </footer>
    )
  }
}


export default Footer;
