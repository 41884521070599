import React, { Component } from "react";
import Values from '../../components/Values';
import Footer from '../../components/Footer';

class Values__PAGE extends Component {
  render() {
    return (
        <div id="Values__PAGE" className="container">
           <Values />
           <Footer />
        </div>
    )
  }
}


export default Values__PAGE;