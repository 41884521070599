import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from "chart.js/auto"
import Papa from 'papaparse';
// import dataCSV from '../data/cannabis_subscriber.csv'
import dataCSV from '../data/cannabis_subscriber__3.csv'

const CustomerArchetypeCustomersGraph = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        const response = await fetch(dataCSV);
        const csvData = await response.text();
        const { data } = Papa.parse(csvData, { header: true });
        setData(data);
    };

    fetchData();
  }, []);

   // Process the CSV data and create the chart datasets
   const groupedData = {};
   data.forEach(item => {
    const date = item.date_monthly;
    const customerArchetype = item.archetype;

    if (!groupedData[date]) {
        groupedData[date] = {
            date,
            curious: 0,
            subscriber: 0,
            trying: 0
        };
    }
    if (customerArchetype === 'Canna Curious') {
        groupedData[date].curious += parseFloat(item.portion_of_monthly_customers);
    } else if (customerArchetype === 'Cannabis Subscriber') {
        groupedData[date].subscriber += parseFloat(item.portion_of_monthly_customers);
    } else if (customerArchetype === "I'll Try Cannabis") {
        groupedData[date].trying += parseFloat(item.portion_of_monthly_customers)
    }
   });

   const uniqueDates = Object.keys(groupedData);
   uniqueDates.sort();
   const curiousSales = uniqueDates.map(date => groupedData[date].curious);
   const subscriberSales = uniqueDates.map(date => groupedData[date].subscriber);
   const tryingSales = uniqueDates.map(date => groupedData[date].trying);

   const chartData = {
    labels: uniqueDates,
    datasets: [
        {
            label: "Cannabis Subscribers as a Percent of Total Cannabis Consumers, CA",
            data: subscriberSales,
            borderColor: '#007961',
            fill: true,
            backgroundColor: 'rgba(0, 121, 97, 0.25)',
        }
    ]
   };

   const chartOptions = {
    maintainAspectRatio: false,
    scales: {
        x: {
            title: {
                display: true,
                // text: 'Date',
                color: 'black',
                font: {
                    size: 12,
                    weight: 'bold'
                }
            }
        },
        y: {
            max: 25,
            beginAtZero: true,
            title: {
                display: true,
                text: 'Percentage of Customers (%)',
                color: 'black',
                font: {
                    size: 12,
                    weight: 'bold'
                }
            }
        }
    },
    plugins: {
        title: {
            display: true,
            // text: 'Cannabis subscribers comprise roughly 5% percetange of total cannabis consumers, yet they drive almost 20% of cannabis sales',
            color: 'black',
            font: {
                size: 20,
                weight: 'bold'
            }
        }
    }
   };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <div style={{ width: '100%', height: '350px' }}>
            <Line data={chartData} options={chartOptions}/>
        </div>
    </div>
  );
};

export default CustomerArchetypeCustomersGraph;