import React, { Component } from 'react';
import CustomerArchetypeCustomersGraph from './CustomerArchetypeCustomersGraph';
import CustomerArchetypeSalesGraph from './CustomerArchetypeSalesGraph';


class IndustryCrack extends Component {
  render() {
    return (
        <div id='insights' className='container'>
            {/* <h6 className='custom-section-header'>STATE OF THE CANNABIS SUBSCRIBER</h6> */}
            {/* <hr/> */}
            <h4
                style={{
                    textAlign: 'center',
                    fontWeight: '700',
                }}
            >
                Cannabis Subscribers comprise roughly 5% of total cannabis consumers, yet they drive almost 20% of dispensary sales
            </h4>


            <div className='row'>
                {/* IndustryCrack's description */}
                <div
                    className='col-lg-12 col-md-12'
                    style={{
                        // fontSize: '1em',
                        // backgroundColor: 'rgba(0,0,0,.05)',
                        // height: '500px'
                    }}
                >
                    <CustomerArchetypeCustomersGraph />
                    <CustomerArchetypeSalesGraph />
                    {/* <iframe
                        src='http://10.23.163.173/crack'
                        // title='DEV Dashboard - Case Study: Known Retailers'
                        width='100%'
                        height='480px'
                        frameBorder='0'
                        style={{
                            marginTop: '10px',
                            marginBottom: '10px'
                        }}
                    />  */}
                </div>
            </div>
            <h6
                className='custom-section-header'
                style={{
                    textAlign:'center',
                    fontSize:'.8em',
                    color:'black',
                    paddingTop: '10px'
                }}
            >
                {/* Average cannabis (flower) prices per product in California */}
            </h6>
            <p
                style={{
                    marginTop: '10px',
                    textAlign: 'center',
                    fontSize: '.8em'
                }}
            >
                {/* To learn more, visit the <a href='https://deltaemerald.substack.com/welcome' target='_blank'>Delta Dispatch</a>. */}
                {/* More industry intelligence coming soon! */}
            </p>
        </div>
    )
  }
}


export default IndustryCrack;