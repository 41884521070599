import React, { Component } from "react";


class Objective extends Component {
  render() {
    return (
        <div id="objective" className="container custom-section-container">
            <h1 className="custom-page-title">
                OUR OBJECTIVE
            </h1>
            <br/>
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <h1
                        style={{
                            color: "rgba(0,0,0,.8)"

                        }}
                    >
                        We're <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>stage-agnostic, industry-focused</span> private equity investors who use <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>proprietary software</span> and <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>deep knowledge</span> to add value. Our investments solve meaningful, durable problems and meet consumer needs.
                    </h1>
                    <br />
                    <br/>
                    <h1
                        style={{
                            color: "rgba(0,0,0,.8)"

                        }}
                    >
                        We envision a future where everyone in the cannabis industry can easily access the information they need. We seek to serve as a <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>central hub</span> for <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>information</span>, <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>talent</span>, and <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>institutional investment</span> as the industry grows.
                    </h1>
                </div>
            </div>
        </div>
    )
  }
}


export default Objective;
