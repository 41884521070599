import React, { Component } from 'react';


import Footer from '../../components/Footer';


class DiligenceTechnology extends Component {
  render() {
    return (
        <div>
          <div id='careers' className='container custom-opportunity-section-container'>
              <h6 className='custom-section-header'><a href="/diligence/welcome">Diligence</a></h6>
              <hr/>

              <h1
                style={{
                    fontSize: '4em',
                    color: 'rgba(0,0,0,.3)'
                }}
              >
                Technology
              </h1>

              <hr/>
              <div className='row'>
                  <div className='col-md-12 mb-12'>
                  {/* <div> */}
                  <div>
                  <div>
                      {/* <div className='py-4 h-100'> */}
                          {/* <div className='card-body'> */}
                            <p
                              style={{
                                marginTop: '10px',
                                marginBottom: '20px',
                              }}
                            >
                              In preparation for our conversation, please prepare documentation addressing the points/questions below.
                            </p>
                            
                            <br />

                            <div>
                              <h5>Architecture</h5>
                              <ul>
                                <li>Architecture flow diagram of software/data services and infrastructure (e.g. how is software broken up into distinct services and how are they hosted?)</li>
                                <li>What technologies are you leveraging across these services?</li>
                                <li>Size, composition, and structure of engineering team.</li>
                                <li>Current challenges.</li>
                              </ul>
                            </div>

                            <div>
                              <h5>Data</h5>
                              <ul>
                                <li>Review of the overall data schema and most important data components.</li>
                                <li>What are your data sources?</li>
                                <li>If applicable, context on existing data pipelines, ETL, and leveraged technologies &amp; infrastructure.</li>
                                <li>Size, composition, and structure of data team.</li>
                                <li>Current challenges.</li>
                              </ul>
                            </div>

                            <div>
                              <h5>Product</h5>
                              <ul>
                                <li>Brief review of product roadmap and how that fits into development roadmap.</li>
                                <li>Product development frameworks (e.g. waterfall, scrum, kanban) &amp; cycles, tooling, and deployment rates.</li>
                                <li>Size, composition, and structure of product team.</li>
                                <li>Current challenges.</li>
                              </ul>
                            </div>

                          </div>

                      </div>
                  </div>
              </div>
          </div>
          <Footer />
        </div>
    )
  }
}


export default DiligenceTechnology;
