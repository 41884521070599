import React, { Component } from "react";


class PrivateEquity extends Component {
  render() {
    return (
        <div id="our-PrivateEquity" className="container custom-section-container">
            <h1 className="custom-page-title">
                PRIVATE EQUITY
            </h1>
            <br/>
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <h1
                        style={{
                            color: "rgba(0,0,0,.9)"

                        }}
                    >
                        We're <span style={{ fontWeight: 'bold' }}>stage-agnostic</span>, <span style={{ fontWeight: 'bold' }}>industry-focused</span> investors. We focus on exceptional teams at the intersection of <span style={{ fontWeight: 'bold' }}>cannabis</span>, <span style={{ fontWeight: 'bold' }}>data</span>, and <span style={{ fontWeight: 'bold' }}>technology</span>.
                    </h1>
                    <br/>
                    <br/>
                    <h1
                        style={{
                            color: "rgba(0,0,0,.9)"

                        }}
                    >
                        <span style={{ fontWeight: 'bold' }}>Data is foundational to our investment thesis and process</span>. Our <a href="/what-we-do/proprietary-data" style={{color:"#65958a"}}>internal data platform</a> improves decision making for our network.
                    </h1>
                    <br/>
                    <br/>
                    <h1
                        style={{
                            color: "rgba(0,0,0,.9)"

                        }}
                    >
                        Find more information about our approach to investing in our <a href="/firm/faq" style={{color:"#65958a"}}>FAQ</a>.
                    </h1>
                </div>
            </div>
        </div>
    )
  }
}


export default PrivateEquity;
