import React, { Component } from "react";


class MagicWand extends Component {
  render() {
    return (
        <div id="MagicWand" className="container custom-section-container">
            <h1 className="custom-page-title">
                MAGIC WAND
            </h1>
            <br/>
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <h1
                        style={{
                            color: "rgba(0,0,0,.7)"

                        }}
                    >
                        What do you wish you knew about the cannabis industry?
                    </h1>
                    <h1
                        style={{
                            color: "rgba(0,0,0,.7)"

                        }}
                    >
                        <a href="https://xfinltgu.paperform.co/" target="_blank" rel="noreferrer" style={{color:"#65958a"}}> Tell us here.</a>
                    </h1>
                </div>
            </div>
        </div>
    )
  }
}


export default MagicWand;
