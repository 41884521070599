import React, { Component } from "react";
import MagicWand from '../../components/MagicWand';
import Footer from '../../components/Footer';


class MagicWand__PAGE extends Component {
  render() {
    return (
        <div id="our-MagicWand" className="container">
            <MagicWand />
            <Footer />
        </div>
    )
  }
}


export default MagicWand__PAGE;