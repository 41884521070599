import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown';

function Privacy() {
    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        fetch('/privacy.md')
            .then(response => response.text())
            .then(text => setMarkdown(text));
    }, []);

    return (
        <div id="Privacy" className="container custom-section-container">
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <ReactMarkdown children={markdown} />
                    
                </div>
            </div>
        </div>
    );
}

export default Privacy;