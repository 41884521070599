import React, { Component } from "react";
import DeltasContent from '../../components/DeltasContent';
import Footer from '../../components/Footer';


class DeltaUnscripted__PAGE extends Component {
  render() {
    return (
        <div id="DeltaUnscripted" className="container">
            <DeltasContent />
            <Footer />
        </div>
    )
  }
}


export default DeltaUnscripted__PAGE;