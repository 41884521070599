import React, { Component } from "react";
import Objective from '../../components/Objective';
import Footer from '../../components/Footer';


class OurObjective__PAGE extends Component {
  render() {
    return (
        <div id="our-objective" className="container">
            <Objective />
            <Footer />
        </div>
    )
  }
}


export default OurObjective__PAGE;