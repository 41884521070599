import React, { Component } from "react";
import Footer from '../../components/Footer';
import Legal from '../../components/Legal';

class Legal__PAGE extends Component {
  render() {
    return (
        <div id="Legal__PAGE" className="container">
           <Legal />
           <Footer />
        </div>
    )
  }
}


export default Legal__PAGE;