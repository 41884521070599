import React, { Component } from 'react';


import Footer from '../../components/Footer';


class DiligenceIntroduction extends Component {
  render() {
    return (
        <div>
          <div id='careers' className='container custom-opportunity-section-container'>
              <h1
                style={{
                    fontSize: '5em',
                    color: 'rgba(0,0,0,.3)'
                }}
              >
                Dear founder...
              </h1>
              
              

              <div className='row'>
                  <div className='col-md-12 mb-12'>
                  {/* <div> */}
                  <div>
                  <div>
                      {/* <div className='py-4 h-100'> */}
                          {/* <div className='card-body'> */}
                            <p
                              style={{
                                marginTop: '10px',
                                marginBottom: '20px',
                                fontSize: '2em',
                                color: 'rgba(0,0,0,.6)'
                              }}
                            >
                              Welcome to our diligence process. We are excited to develop a relationship with you and your team as we explore a partnership.
                            </p>

                            <hr />
                            <br/>

                            <p>
                                On this page you will learn about the different phases of our diligence process, and (if applicable) the components of your venture that we'd like to learn about.
                            </p>

                            <br/>

                            <div>

                              <h3><a href="/diligence/introduction" target="_blank">1) Introduction</a></h3>
                              <ul>
                                <li>In this phase our goal is to meet your venture's management team, introduce ourselves and our differentiation in the cannabis space, and understand your venture's positioning in the value supply-chain.</li>
                              </ul>

                              <h3><a href="/diligence/business" target="_blank">2) Business</a></h3>
                              <ul>
                                <li>In this phase our goal is to understand your business model, with an emphasis on problem discovery, customer development, market validation, product/service demonstration, user journeys, usage statistics, notable customers, and customer churn.</li>
                              </ul>

                              <h3><a href="/diligence/finance" target="_blank">3) Finance</a></h3>
                              <ul>
                                <li>In this phase our goal is to understand the details of your venture's financial model, revenues &amp; expenses, unit economics, forecasts, customer acquisition costs, and other financial details (as applicable).</li>
                              </ul>

                              <h3><a href="/diligence/technology" target="_blank">4) Technology</a></h3>
                              <ul>
                                <li>In this phase our goals is to understand the technology and data components that power your product/service artifacts, as well as internal operations (as applicable).</li>
                              </ul>

                              <h3>5) References</h3>
                              <ul>
                                <li>In this phase our goal is to connect with key customers and/or partners of yours to learn their perspective and gather feedback.</li>
                              </ul>

                              <h3>6) Closing</h3>
                              <ul>
                                <li>In this phase our goal is to corporate documents, taxes, regulations &amp; licenses, human resources, and intellectual property (as applicable).</li>
                              </ul>

                              <h3>7) Funding</h3>
                              <ul>
                                <li>In this phase our goal is to wire funds to you to support you as you grow. At this point we are excited to formally deploy our partnership and support you strategically and technologically.</li>
                              </ul>

                            </div>

                            <br />

                            <p>
                                Depending on how quickly we can move through the phases above, we can conclude our diligence process in 3 to 7 weeks.
                            </p>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <Footer />
        </div>
    )
  }
}


export default DiligenceIntroduction;
