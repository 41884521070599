import React, { Component } from "react";

import delta_designers from '../assets/delta_designers.jpg'

class DeltaDesigners extends Component {
  render() {
    return (
        <div id="DeltaDesigners" className="container custom-section-container" style={{textAlign:'center'}}>
            {/* <h1 className="custom-page-title">
                DELTA DESIGNERS
            </h1> */}
            <br/>
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <img
                        // className='zoom'
                        src={delta_designers}
                        style={{
                            margin:'0px',
                            width:'300px'
                        }}
                        alt='delta_designers'
                    ></img>
                </div>
            </div>
        </div>
    )
  }
}


export default DeltaDesigners;
