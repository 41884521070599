import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown';
// import htmlParser from 'html-react-parser';
// import rehypeRaw from 'rehype-raw';

function FAQ() {
    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        fetch('/faq.md')
            .then(response => response.text())
            .then(text => setMarkdown(text));
    }, []);

    return (
        <div id="FAQ" className="container custom-section-container">
            <h1 className="custom-page-title">
                FREQUENTLY ASKED QUESTIONS
            </h1>
            <br />
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    {/* <ReactMarkdown children={markdown} escapeHtml={false} renderers={{ html: htmlParser() }} /> */}
                    {/* <ReactMarkdown rehypePlugins={[rehypeRaw]}> */}
                    <ReactMarkdown>
                        {markdown}
                    </ReactMarkdown>
                </div>
            </div>
        </div>
    );
}

export default FAQ;