import React, { Component } from 'react';


import Footer from '../../components/Footer';


class DiligenceIntroduction extends Component {
  render() {
    return (
        <div>
          <div id='careers' className='container custom-opportunity-section-container'>
              <h6 className='custom-section-header'><a href="/diligence/welcome">Diligence</a></h6>
              <hr/>

              <h1
                style={{
                    fontSize: '4em',
                    color: 'rgba(0,0,0,.3)'
                }}
              >
                Introduction
              </h1>

              <hr/>
              <div className='row'>
                  <div className='col-md-12 mb-12'>
                  {/* <div> */}
                  <div>
                  <div>
                            <p
                              style={{
                                marginTop: '10px',
                                marginBottom: '20px',
                              }}
                              >
                              In preparation for our conversation, please prepare documentation addressing the points/questions below.
                            </p>
                            <br/>
                            <div>

                              <h5>Team</h5>
                              <ul>
                                <li>Management team’s background?</li>
                                <li>Amount of employees?</li>
                                <li>What roles do you need to hire for immediately?</li>
                              </ul>

                              <h5>Business Model</h5>
                              <ul>
                                <li>Please describe your business model in a few sentences.</li>
                              </ul>
                              <h5>Market Opportunity</h5>
                              <ul>
                                <li>What are the size(s) of the end market(s) you are serving?</li>
                                <li>What is your value/supply chain ecosystem, and how do you fit into it?</li>
                                <li>Do you currently have any licenses or need any licenses to operate?</li>
                                <li>In what states/regions do you operate?</li>
                              </ul>
                              <h5>Customer Segments</h5>
                              <ul>
                                <li>What are your customer segments/archetypes?</li>
                                <li>How many paying customers do you have?</li>
                              </ul>
                              <h5>Solution Space</h5>
                              <ul>
                                <li>What is your product/service and current feature set?</li>
                                <li>What are the unit economics for each product or service?</li>
                              </ul>
                              <h5>Scalability</h5>
                              <ul>
                                <li>What’s the most challenging part of the business model to achieve?</li>
                                <li>What are the biggest risks to your business plan?</li>
                                <li>What still needs to be proved out in the business plan?</li>
                                <li>If you could get the top Google search query, what would it be?</li>
                              </ul>

                              <h5>Financials</h5>
                              <ul>
                                <li>Fundraising history?</li>
                                <li>Revenue history?</li>
                                <li>Cash balance?</li>
                                <li>Monthly burn rate?</li>
                                <li>% equity owned by founders today?</li>
                              </ul>

                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <Footer />
        </div>
    )
  }
}


export default DiligenceIntroduction;
