import React, { Component } from "react";
import Team from '../../components/Team';
import Footer from '../../components/Footer';


class OurPeople__PAGE extends Component {
  render() {
    return (
        <div id="OurPeople__PAGE" className="container">
           <Team />
           <Footer />
        </div>
    )
  }
}


export default OurPeople__PAGE;