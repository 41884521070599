import React, { Component } from 'react';
import DispatchCard from '../components/DispatchCard'; 


class DispatchClickbait extends Component {
    constructor(props) {
        super(props);

        this.state = {
            article_1: {
                id: 1,
                title: "Introducing the Cannabis Subscriber",
                subtitle: "Cannabis transaction clustering analysis reveals the \u201cCannabis Subscriber\u201d customer archetype, who spends $200 - $400 per month on cannabis across 2 - 7 transactions and makes a repeat purchase every ~60 days.",
                link: "https://deltaemerald.substack.com/p/the-cannabis-subscriber-part-iii"
            },
            article_2: {
                id: 2,
                title: "How online and in-store prices distort cannabis price comparisons",
                subtitle: "There\u2019s a world of difference between cannabis online menu pricing and in-store \u201cout the door\u201d pricing.",
                link: "https://deltaemerald.substack.com/p/laziness-killed-the-out-the-door"
            },
            article_3: {
                id: 3,
                title: "Is employee churn the cannabis industry\u2019s biggest cost?",
                subtitle: "Our data shows 40% annualized churn among MSOs, suggesting these companies are finding it difficult to hire and retain talent.",
                link: "https://deltaemerald.substack.com/p/help-wanted-part-2-cannabis-churn"
            },
            article_4: {
                id: 4,
                title: "How might we compare a cannabis subscription to other subscriptions?",
                subtitle: "The Cannabis Subscriber spends $200-400 per month on cannabis. Compared to Netflix, Amazon, and others, the Cannabis Subscriber looks more valuable in both quantum and frequency of spend.",
                link: "https://deltaemerald.substack.com/p/the-cannabis-subscriber-part-ii"
            },
            article_5: {
                id: 5,
                title: "Who is the Cannabis Subscriber?",
                subtitle: "Who are these Cannabis Subscribers? 20-to-40 year-olds who have tripled their monthly cannabis wallet spend in two years.",
                link: "https://deltaemerald.substack.com/p/the-cannabis-subscriber-part-ii"
            },
            article_6: {
                id: 6,
                title: "Are Cannabis Subscribers brand loyal or brand promiscuous?",
                subtitle: "Cannabis Subscribers are less brand loyal than the average customer.",
                link: "https://deltaemerald.substack.com/p/the-cannabis-subscriber-part-ii"
            },
            article_7: {
                id: 7,
                title: "How much is a Cannabis Subscriber worth?",
                subtitle: "Cannabis Subscribers become more valuable over time.",
                link: "https://deltaemerald.substack.com/p/the-cannabis-subscriber-part-ii"
            },
            article_8: {
                id: 8,
                title: "Why 4/20 isn\u2019t all it\u2019s cracked up to be",
                subtitle: "Cannabis Subscribers save their biggest purchases for other times of the year. On 4/20, clever operators know to offer these customers a unique product instead of competing on price.",
                link: "https://deltaemerald.substack.com/p/420-and-the-cannabis-subscriber"
            },
            article_9: {
                id: 9,
                title: "Cannabis Subscribers prefer in-store shopping",
                subtitle: "We found that ~81% of Cannabis subscribers were \u2018retail-only\u2019 customers, ~10% were \u201chybrid\u201d, and ~9% were \u2018delivery-only.\u2019",
                link: "https://deltaemerald.substack.com/p/the-cannabis-subscriber-part-iii"
            },
            article_10: {
                id: 10,
                title: "How technical debt is like a dirty kitchen sink",
                subtitle: "Technical debt is not unlike operational or financial leverage, and unavoidable. But If not managed properly, technical debt severely restricts product development.",
                link: "https://deltaemerald.substack.com/p/the-cannabis-subscriber-part-iii"
            },
            article_11: {
                id: 11,
                title: "Consumers want more cannabis dispensaries",
                subtitle: "Wherein we look at liquor store density to estimate the potential size of the cannabis dispensary channel at maturity.",
                link: "https://deltaemerald.substack.com/p/eyes-on-the-prize-part-ii-who-cares"
            },
            article_12: {
                id: 12,
                title: "How we get to a $124bn retail market without federal legalization",
                subtitle: "The U.S. has whitespace for at least 45,000 cannabis dispensaries at maturity. This excludes other sales channels, which are already percolating through the economy.",
                link: "https://deltaemerald.substack.com/p/eyes-on-the-prize-part-ii-who-cares"
            },
            article_13: {
                id: 13,
                title: "The half-trillion US cannabis economy",
                subtitle: "We see a clear path to a half-trillion US cannabis economy, and there\u2019s easy upside to this estimate if you think federal legalization will open up grocery, convenience, and on-premise sales channels.",
                link: "https://deltaemerald.substack.com/p/eyes-on-the-prize-part-iii-the-half"
            },
            article_14: {
                id: 14,
                title: "How much could retail pharma add to the cannabis economy?",
                subtitle: "Pharmacy sales represent 37% of sales for all food and beverage outlets. That proportion has been consistent for the last 15 years and suggests the opportunity for cannabis pharmaceutical retail sales could be $46bn.",
                link: "https://deltaemerald.substack.com/p/eyes-on-the-prize-part-iii-the-half"
            },
            article_15: {
                id: 15,
                title: "What do cannabis operators spend on technology?",
                subtitle: "Delta Emerald data suggests cannabis operators spend 3-7% of their revenue on technology. The gap between 3% and 7% is driven by whether a company is in growth mode or not, based on our research.",
                link: "https://deltaemerald.substack.com/p/eyes-on-the-prize-part-iv-the-dirty"
            },
            article_16: {
                id: 16,
                title: "How much does the entire cannabis industry spend on technology?",
                subtitle: "We estimate $900mm of U.S. cannabis tech spend in 2023. Our research suggests half of this spend is spent on CannaTech, which we define as software specifically made for the cannabis industry.",
                link: "https://deltaemerald.substack.com/p/eyes-on-the-prize-part-iv-the-dirty"
            },
            article_17: {
                id: 17,
                title: "The coming tidal wave of cannabis technology spend",
                subtitle: "We expect cannabis technology spend to reach $30bn in ten years, or $107bn of cumulative spend over the period.",
                link: "https://deltaemerald.substack.com/p/eyes-on-the-prize-part-iv-the-dirty"
            }
        }

        // this.state = {
        //     article_1: {
        //         id: 1,
        //         title: "The Cannabis Subscriber, part I",
        //         subtitle: "Cannabis is more similar to Netflix than you think – except, you can't smoke a Netflix...",
        //         link: "https://deltaemerald.substack.com/p/the-cannabis-subscriber-part-i"
        //     },
        //     article_2: {
        //         id: 2,
        //         title: "The Cannabis Subscriber, part II",
        //         subtitle: "If the Cannabis Subscriber spends up to $400 per month on cannabis, are they more valuable than 'Netflix and chill'?",
        //         link: "https://deltaemerald.substack.com/p/the-cannabis-subscriber-part-ii"
        //     },
        //     article_3: {
        //         id: 3,
        //         title: "The Cannabis Subscriber, part III",
        //         subtitle: "For a dispensary’s biggest spenders, nothing beats shopping in-store. And you thought stoners were lazy...",
        //         link: "https://deltaemerald.substack.com/p/the-cannabis-subscriber-part-iii"

        //     }
        // }
    }
    render() {
    return (
        <div id='DispatchClickbait' className='container custom-section-container'>
            <h1 className="custom-page-title">
                OUR IN-DEPTH ANALYSIS OF THE CANNABIS CONSUMER
            </h1>
            <hr/>

            <div className='row'>
                <DispatchCard dispatch_data={this.state.article_1} />
                <DispatchCard dispatch_data={this.state.article_2} />
                <DispatchCard dispatch_data={this.state.article_3} />
                <DispatchCard dispatch_data={this.state.article_4} />
                <DispatchCard dispatch_data={this.state.article_5} />
                <DispatchCard dispatch_data={this.state.article_6} />
                <DispatchCard dispatch_data={this.state.article_7} />
                <DispatchCard dispatch_data={this.state.article_8} />
                <DispatchCard dispatch_data={this.state.article_9} />
                <DispatchCard dispatch_data={this.state.article_10} />
                <DispatchCard dispatch_data={this.state.article_11} />
                <DispatchCard dispatch_data={this.state.article_12} />
                <DispatchCard dispatch_data={this.state.article_13} />
                <DispatchCard dispatch_data={this.state.article_14} />
                <DispatchCard dispatch_data={this.state.article_15} />
                <DispatchCard dispatch_data={this.state.article_16} />
            </div>
            <p
                style={{
                    marginTop: '10px',
                    textAlign: 'center',
                    fontSize: '.8em'
                }}
            >
                To learn more, visit the <a href='https://deltaemerald.substack.com/welcome' target='_blank' rel="noreferrer">Delta Dispatch</a>.
            </p>
        </div>
    )
  }
}


export default DispatchClickbait;