import React, { Component } from 'react';


class Testimonials extends Component {
  render() {
    return (
        <div id='Testimonials' className='container custom-section-container'>
            <h1 className="custom-page-title">
                TESTIMONIALS FROM FOUNDERS
            </h1>
            <hr/>
            <div className='row'>
                <div
                    className='col-lg-12 col-md-12'
                    style={{
                        marginTop: '50px',
                        paddingLeft: '50px',
                        paddingRight: '50px',
                        fontSize: '1em',
                        fontStyle: 'italic'
                        
                    }}
                >
                    <p>
                        "As a technical founder it's essential that our closest investor understands not only our business, but fundamentally how our technology works. With Delta Emerald we can debate the value, vision, and scale of our technical stack, creating a layer of trust that makes me feel comfortable sharing deep details of our ongoing operations, opportunities, and decisions.
                    </p>
                    <p>
                        Delta Emerald's decision to invest as a fund into their own internal technical expertise gives them unparalleled strategic insight and intelligence.
                    </p>
                    <p>
                        Lastly, as a founder of color it is refreshing to work with a close partner who has invested in a team with a diverse skill set and diverse backgrounds that culturally meet us where we are as a business."
                    </p>
                    
                    - <strong>Andrew Watson</strong>, Happy Cabbage Analytics
                </div>
 
                <div
                    className='col-lg-12 col-md-12'
                    style={{
                        marginTop: '50px',
                        paddingLeft: '50px',
                        paddingRight: '50px',
                        fontSize: '1em',
                        fontStyle: 'italic'

                    }}
                >
                    <p>
                        "We are so grateful to have found Delta Emerald - who has not only provided us with capital across three raises, but has proven themselves as true partners in the journey. Their expertise in data and technology makes them an invaluable resource to our team - and true thought partners who have earned our trust and respect.
                    </p>
                    <p>
                        Delta Emerald was one of our first clients in our data program years before the rest of the industry saw the value. They see things before others do - and they have the courage to commit themselves and their resources to the things they believe in."
                    </p>

                    
                    - <strong>Socrates Rosenfeld</strong>, Jane Technologies
                </div>
                
            </div>
        </div>
    )
  }
}


export default Testimonials;