import React, { Component } from "react";
import DeltaDesigners from '../../components/DeltaDesigners';
import Footer from '../../components/Footer';


class DeltaDesigners__PAGE extends Component {
  render() {
    return (
        <div id="our-Testimonials" className="container">
            <DeltaDesigners />
            <Footer />
        </div>
    )
  }
}


export default DeltaDesigners__PAGE;