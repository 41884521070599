import React, { Component } from "react";


class ProprietaryData extends Component {
  render() {
    return (
        <div id="ProprietaryData" className="container custom-section-container">
            <h1 className="custom-page-title">
                PROPRIETARY DATA
            </h1>
            <br/>
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <h1
                        style={{
                            color: "rgba(0,0,0,.9)"

                        }}
                    >
                        For Delta Emerald and our portfolio companies, <span style={{ fontWeight: 'bold' }}>mastering and effectively using data is essential</span>.
                    </h1>
                    <br/>
                    <br/>
                    <h1
                        style={{
                            color: "rgba(0,0,0,.9)"

                        }}
                    >
                        We leverage data to enhance our portfolio companies' performance and guide our investment decisions.
                    </h1>
                </div>
            </div>
        </div>
    )
  }
}


export default ProprietaryData;
