import React, { Component } from "react";
import FAQ from '../../components/FAQ';
import Footer from '../../components/Footer';



class OurObjective__PAGE extends Component {
  render() {
    return (
        <div id="FAQ" className="container">
            <FAQ />
            <Footer />
        </div>
    )
  }
}


export default OurObjective__PAGE;