import React, { Component } from "react";
import Portfolio from '../../components/Portfolio';
import Footer from '../../components/Footer';

class OurPortfolio__PAGE extends Component {
  render() {
    return (
        <div id="OurPortfolio__PAGE" className="container">
           <Portfolio />
           <Footer/>
        </div>
    )
  }
}


export default OurPortfolio__PAGE;


